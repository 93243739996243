import CancelIcon from "@/svg/cancel.svg?react";
import CircleCheckIcon from "@/svg/circle-check.svg?react";
import OpenInNewIcon from "@/svg/open-in-new.svg?react";
import classNames from "classnames";
import React from "react";
import useMeasuring from "../Hooks/MeasuringHook";
import CutText from "./CutText";
import FileInput, { FileInputProps } from "./Form/FileInput";
import Input, { InputProps } from "./Form/Input";
import IconButton from "./IconButton";

export default function PropertyForm({
    editable,
    properties,
}: {
    editable: boolean;
    properties: {
        label: string;
        value: string | number | undefined;
        cutLength?: number;
        input?: (({
            inputType?: undefined,
        } & Omit<InputProps, "label">) | ({
            inputType: "file",
            previewUrl?: string,
        } & Omit<FileInputProps, "label">)) & {
            label?: string | "{useLabel}"
        };
    }[];
}) {

    const [ref, { width }] = useMeasuring<HTMLDivElement>();

    return (
        <div ref={ref} className={classNames({
            "flex flex-col justify-center gap-5": editable || width < 500,
            "grid grid-cols-[auto_1fr] gap-y-5 gap-x-5": !editable && width >= 500,
        })}>
            {editable ? (
                <>
                    {properties.flatMap(({ label, input }) => input ? [
                        (() => {
                            switch (input.inputType) {
                                case "file":
                                    return (
                                        <FileInput
                                            key={label}
                                            {...input}
                                            label={input.label === "{useLabel}" ? label : input.label}
                                        />
                                    );
                                case undefined:
                                    return (
                                        <Input
                                            key={input.name}
                                            {...input}
                                            label={input.label === "{useLabel}" ? label : input.label}
                                        />
                                    );
                            }
                        })()
                    ] : [])}
                </>
            ) : (
                <>
                    {properties.map(({ cutLength, label, value, input}) => (
                        <React.Fragment key={label}>
                            <h2 className={classNames("bd-l font-medium", {
                                "self-center": input?.inputType === "file",
                            })}>{label}</h2>
                            <div className={classNames("bd-l", { "mt-[-15px] bd-m": width < 500 })}>
                                {input?.inputType === "file" ? (
                                    <div className="flex items-center gap-2">
                                        {value === undefined ? (
                                            <>
                                                <CancelIcon className="size-7 fill-error" />
                                                <span>Nincs fájl feltöltve</span>
                                            </>
                                        ) : (
                                            <>
                                                <CircleCheckIcon className="size-7 fill-primary" />
                                                <span>Fájl feltöltve</span>
                                                {input.previewUrl && (
                                                    <IconButton
                                                        variant="filled-tonal"
                                                        to={input.previewUrl}
                                                        compact
                                                    >
                                                        <OpenInNewIcon />
                                                    </IconButton>
                                                )}
                                            </>
                                        )}
                                        
                                    </div>
                                ) : (
                                    cutLength === undefined ? (
                                        <span>{value ?? "-"}</span>
                                    ) : (
                                        <CutText maxLength={cutLength} text={value?.toString() ?? "-"} />
                                    )
                                )}
                            </div>
                        </React.Fragment>
                    ))}
                </>
            )}
        </div>
    );
}