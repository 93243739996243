import { Image } from "@/js/Components/Image";
import useRedirectUrl from "@/js/Hooks/SuccessfulDonationRedirectUrlHook";
import { useDonationSuccessful } from "@/js/Layouts/Public";
import { AMBASSADOR_IMAGE_ASPECT_RATIO, CAMPAIGN_IMAGE_ASPECT_RATIO } from "@/js/common";
import { usePublicCampaignAmbassadors, usePublicOrganizationCampaigns } from "@/js/resources";
import React, { useMemo } from "react";
import { Navigate, useParams } from "react-router";

export default function DonationSuccessful() {
    const [donationSuccess] = useDonationSuccessful(); 
    const { organizationId, campaignId, ambassadorId } = useParams();

    const redirectUrl = useRedirectUrl();

    const [ambassador] = usePublicCampaignAmbassadors({
        id: Number(ambassadorId ?? 0),
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
        autoRefresh: ambassadorId !== undefined,
    })

    const [campaign] = usePublicOrganizationCampaigns({
        id: Number(campaignId ?? 0),
        params: useMemo(() => ({
            organization: organizationId
        }), [organizationId]),
        autoRefresh: campaignId !== undefined,
    })

    if (donationSuccess !== "success") {
        return <Navigate to={redirectUrl} />
    }

    return (
        <div className="flex flex-col w-full h-full gap-12 justify-center items-center p-4">
            {ambassador ? (
                <Image
                    resourceImage={ambassador.image ?? undefined}
                    className="w-full sm:w-[300px]"
                    aspectRatio={AMBASSADOR_IMAGE_ASPECT_RATIO}
                />
            ) : (
                campaign && (
                    <Image
                        resourceImage={campaign.image ?? undefined}
                        className="w-full sm:w-[500px]"
                        aspectRatio={CAMPAIGN_IMAGE_ASPECT_RATIO}
                    />
                )
            )}
            <div className="flex flex-col gap-5 items-center text-center">
                <h1 className="hd-l">{ambassadorId ? "Köszönöm szépen!" : "Köszönjük szépen!"}</h1>
                <span className="bd-l whitespace-pre-wrap">
                    {campaignId !== undefined ? campaign?.donation_success_message : "Az adományod sikeresen megérkezett. Köszönjük, hogy támogatod szervezetünket!"}
                </span>
            </div>
        </div>
    )
}