import createResource from "@enymo/react-resource-hook";
import { PositionRatio } from "./Components/Image";
import { languages } from "./common";

export interface PositionableImage {
    id: string,
    position: PositionRatio,
    file?: File | null,
}

export interface ApiFile {
    id: string,
    name: string,
    disk: string,
    mime_type: string,
}

export interface User {
    id: number | "me",
    organization_id: number,
    first_name: string,
    last_name: string,
    email: string,
    language: typeof languages[number],
    jwt: string,
}

export interface UserUpdate extends User {
    password?: string,
}

export const [useUsers, UserProvider] = createResource<User, UserUpdate>("users");

export interface Organization {
    id: number,
    name: string,
    email: string,
    gdpr_url: string,
    website?: string,
    facebook?: string,
    instagram?: string,
    image?: PositionableImage | null,
    description_short: string,
    description_long: string,
    donation_email_template: ApiFile | null,
}

export const [useOrganizations, OrganizationProvider] = createResource<Organization>("organizations");

export const [useOrganizationPositionableImages] = createResource<PositionableImage>("organizations.positionable-images");

export const [useOrganizationUsers] = createResource<User>("organizations.users");

export interface InvitationToken {
    id: number,
    token: string,
    organization_id: number,
    email: string,
    valid_until: Date,
}

export interface ApiInvitationToken extends Omit<InvitationToken, "valid_until"> {
    valid_until: string,
}

export const invitationTokenTransformer = (token: ApiInvitationToken): InvitationToken => ({
    ...token,
    valid_until: new Date(token.valid_until)
});

export const [useOrganizationInvitationTokens] = createResource<InvitationToken>("organizations.invitation-tokens", {
    transformer: invitationTokenTransformer,
});

export interface Campaign {
    id: number,
    name: string,
    description: string,
    active: boolean,
    published: boolean,
    donation_amount: number,
    organization_id: number,
    donation_goal: number,
    created_at: Date,
    image?: PositionableImage | null,
    donation_success_message: string,
    donation_email_template: ApiFile | null,
    campaign_end_email_template: ApiFile | null,
}

interface ApiCampaign extends Omit<Campaign, "created_at"> {
    created_at: string,
}

const campaignTransformer = (campaign: ApiCampaign): Campaign => ({
    ...campaign,
    created_at: new Date(campaign.created_at),
})

export const [useCampaigns, CampaignProvider] = createResource<Campaign>("campaigns", {
    transformer: campaignTransformer,
});

export interface Donation {
    id: number,
    amount: number,
    donationable_id: number,
    donationable_type: "App\\Models\\Campaign" | "App\\Models\\Ambassador",
    note: string,
    approved: boolean,
    donor_name: string | null,
    donor_email: string | null,
    created_at: Date,
    anonym: boolean,
    hide_amount: boolean,
    ambassador_name?: string,
}

interface ApiDonation extends Omit<Donation, "created_at"> {
    created_at: string,
}

const donationTransformer = (donation: ApiDonation): Donation => ({
    ...donation,
    created_at: new Date(donation.created_at)
});

export const [useCampaignDonations, CampaignDonationsProvider] = createResource<Donation>("campaigns.donations", {
    transformer: donationTransformer,
});

export interface Ambassador {
    id: number,
    name: string,
    email: string,
    info: string,
    campaign_id: number,
    end_date: Date | null,
    donation_amount: number,
    donation_goal: number,
    image?: PositionableImage | null,
}

interface ApiAmbassador extends Omit<Ambassador, "end_date"> {
    end_date: string | null,
}

const ambassadorTransformer = (ambassador: ApiAmbassador): Ambassador => ({
    ...ambassador,
    end_date: ambassador.end_date ? new Date(ambassador.end_date) : null,
});

export const [useCampaignAmbassadors, CampaignAmbassadorsProvider] = createResource<Ambassador>("campaigns.ambassadors", {
    transformer: ambassadorTransformer,
});
export const [useAmbassadorDonations] = createResource<Donation>("ambassadors.donations", {
    transformer: donationTransformer,
});

export interface PublicOrganization extends Organization {
    gallery_images: PositionableImage[],
}

export const [usePublicOrganizations, PublicOrganizationProvider] = createResource<PublicOrganization>("public.organizations");

interface PublicCampaign extends Omit<Campaign, "active"> {
    active: boolean,
}

const publicCampaignTransformer = (campaign: ApiCampaign): PublicCampaign => ({
    ...campaignTransformer(campaign),
    active: campaign.active,
});

export const [usePublicOrganizationCampaigns, PublicOrganizationCampaignProvider] = createResource<PublicCampaign>("public.organizations.campaigns", {
    transformer: publicCampaignTransformer,
});
export const [usePublicCampaignAmbassadors, PublicCampaignAmbassadorsProvider] = createResource<Ambassador>("public.campaigns.ambassadors", {
    transformer: ambassadorTransformer,
});
export const [usePublicAmbassadorDonations, PublicAmbassadorDonationsProvider] = createResource<Donation>("public.ambassadors.donations", {
    transformer: donationTransformer,
});

export const [usePublicCampaignDonations] = createResource<Donation>("public.campaigns.donations", {
    transformer: donationTransformer,
});

export interface StripePrice {
    id: string,
    unit_amount: number,
    currency: string,
}

export const [useStripePrices] = createResource<StripePrice>("public.stripe-prices");