import { Form } from "@enymo/react-form-component";
import { Elements, ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { route } from "ziggy-js";
import { EmailRegex, toCurrency } from "../common";
import { Checkbox } from "../glidespec";
import useStripeElementsProps from "../Hooks/StripeElementsHook";
import useStripeHandleSubmit from "../Hooks/StripeSubmissionHook";
import useRedirectUrl from "../Hooks/SuccessfulDonationRedirectUrlHook";
import { useDonationSuccessful, useOrganization } from "../Layouts/Public";
import Button from "./Form/Button";
import Input from "./Form/Input";

interface Submit {
    donor_name: string;
    donor_email: string;
    amount: number;
    note: string;
    hide_amount: boolean;
    cover_costs: boolean;
    anonym: boolean;
}

export default function DonationForm() {
    const { organizationId, campaignId, ambassadorId } = useParams();
    const navigate = useNavigate();
    const [organization] = useOrganization();
    const [, setDonationSuccess] = useDonationSuccessful();

    const form = useForm<Submit>({
        mode: "all",
        defaultValues: {
            hide_amount: false,
        }
    });
    const amount = form.watch("amount");
    const name = form.watch("donor_name");
    const coverCosts = form.watch("cover_costs");

    const donee = useMemo(() => ambassadorId ? "ambassadors" : campaignId ? "campaigns" : "organizations", [ambassadorId, campaignId]);
    const redirectUrl = useRedirectUrl(true);
    const totalAmount = useMemo(() => amount ? Math.floor(coverCosts ? amount * 1.015 + 85 : amount) : undefined, [coverCosts, amount]);
    const handleSubmit = useStripeHandleSubmit(
        (data: Submit) => axios.post<string>(route(`public.${donee}.donate`, {
            ambassador: ambassadorId,
            campaign: campaignId,
            organization: organizationId,
        }), {
            ...data,
            amount: totalAmount,
        }).then(response => response.data),
        () => {
            setDonationSuccess("success")
            navigate(redirectUrl);
        },
        [totalAmount, donee, ambassadorId, campaignId, organizationId, redirectUrl]
    )

    const elementsProps = useStripeElementsProps({
        mode: "payment",
        amount: (totalAmount && totalAmount >= 500 ? totalAmount : 500) * 100,
    }, [totalAmount])

    return (
        <div className="flex flex-col gap-5">
            {donee !== "organizations" && <h2 className="ttl-l text-primary">Adományozás</h2>}
            <Elements {...elementsProps}>
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <Form form={form} onSubmit={handleSubmit(elements ?? undefined, stripe)} className="flex flex-col gap-4">
                            <Input name="donor_name" label="Név" />
                            {name && donee !== "organizations" && (
                                <div className="flex flex-col gap-1">
                                    <Checkbox name="anonym">
                                        Nyilvánosan ne jelenjen meg a nevem
                                    </Checkbox>
                                    <p className="bd-s ml-1">
                                        A kampány szervezői továbbra is látni fogják a neved.
                                    </p>
                                </div>
                            )}
                            <div className="flex flex-col gap-1">
                                <Input name="donor_email" label="Email" options={{
                                    pattern: {
                                        value: EmailRegex,
                                        message: "Adj meg egy érvényes e-mail címet!"
                                    },
                                }} />
                            </div>
                            {donee !== "organizations" && (
                                <Input name="note" label="Megjegyzés" />
                            )}
                            <Input name="amount" label="Összeg" type="number" suffix="Ft" options={{
                                required: "Kérjük add meg az összeget!",
                                validate: (value) => value >= 500 || "Minimum 500 Ft-tal támogathatod a kampányt!",
                            }} />
                            {amount && amount >= 500 && (
                                <div className="flex flex-col gap-4">
                                    {name && (
                                        <div className="flex flex-col gap-1">
                                            <Checkbox name="hide_amount">
                                                Összeg elrejtése
                                            </Checkbox>
                                            <p className="bd-s ml-1">
                                                A kampány szervezői továbbra is látni fogják az összeget, de nyilvánosan nem jelenik meg.
                                            </p>
                                        </div>
                                )}
                                    <div className="flex flex-col gap-1">
                                        <Checkbox name="cover_costs">
                                            Kifizetem a kezelési költségeket
                                        </Checkbox>
                                        <p className="bd-s ml-1">
                                            A fizetések kezelését egy harmadik fél végzi, mely kezelési költésgeket számol fel. Ez ennél a tranzakciónál <span style={{
                                                fontWeight: "500"
                                            }}>{toCurrency(amount * 0.015 + 85)}</span>
                                        </p>
                                    </div>
                                </div>
                            )}
                            {totalAmount && totalAmount >= 500 && (
                                <div style={{
                                    margin: "20px 0",
                                }}>
                                    <PaymentElement options={{
                                        layout: "accordion",
                                    }} />
                                </div>
                            )}
                            <div className="flex flex-col gap-2 items-center">
                                <span className="bd-s text-center">
                                    Az adományozás gombra kattintással elfogadod az <a href={organization?.gdpr_url} target="_blank" rel="noreferrer" className="text-primary">adatvédelmi irányelveket</a>.
                                </span>
                                <Button variant="filled" disabled={!(totalAmount && totalAmount >= 500)} submit>Adományozás</Button>
                            </div>
                        </Form>
                    )}
                </ElementsConsumer>
            </Elements>
        </div>
    )
}