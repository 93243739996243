import DonationForm from "@/js/Components/DonationForm";
import DonationsTable from "@/js/Components/DonationsTable";
import { Image } from "@/js/Components/Image";
import Loader from "@/js/Components/Loader";
import StaticProgressBar from "@/js/Components/StaticProgressBar";
import { useAlert } from "@/js/Layouts/Public";
import { useAmbassador } from "@/js/Providers/Public/AmbassadorProvider";
import { useCampaign } from "@/js/Providers/Public/CampaignProvider";
import { AMBASSADOR_IMAGE_ASPECT_RATIO, toCurrency } from "@/js/common";
import { usePublicAmbassadorDonations } from "@/js/resources";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

export default function Ambassador() {
    const { organizationId, campaignId, ambassadorId } = useParams();
    const [ambassador] = useAmbassador();

    const [donations] = usePublicAmbassadorDonations({
        params: useMemo(() => ({
            ambassador: ambassadorId
        }), [ambassadorId]),
    });

    const [campaign] = useCampaign();

    useAlert(((campaign?.active ?? true) && (ambassador?.end_date ? (ambassador.end_date < new Date()) : false)) ? {
        type: "error",
        title: "A nagykövet kampánya lejárt!",
        message: "Sajnos ez a kampány már lezárult, így nem tudsz adományozni."
    } : null);

    return (
        <div className="flex flex-col flex-1 pt-5 pb-12 max-w-[1200px] self-center w-full">
            {ambassador ? (
                <div className="flex flex-col gap-10 flex-1">
                    <div className="flex flex-col sm:flex-row gap-10">
                        <Image
                            resourceImage={ambassador.image ?? undefined}
                            aspectRatio={AMBASSADOR_IMAGE_ASPECT_RATIO}
                            className="w-full sm:w-[250px]"
                        />
                        <div className="flex flex-col gap-5">
                            <div className="flex flex-col gap-1">
                                <h1 className="hd-s text-primary">{ambassador?.name}</h1>
                                <h2 className="ttl-m">
                                    Kampány: <Link
                                        to={`/public/organizations/${organizationId}/campaigns/${campaignId}`} 
                                        className="interactive-bg-secondary-container p-1 rounded-md">
                                            {campaign?.name}
                                        </Link>
                                </h2>
                            </div>
                            <span style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}>{ambassador?.info}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">
                        <h2 className="ttl-l text-primary">Eddigi adományok</h2>
                        <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                            <div className="flex flex-col gap-3">
                                <div className="flex justify-between gap-3">
                                    <span>Összesen:</span>
                                    <span>{toCurrency(ambassador.donation_amount)}</span>
                                </div>
                                <div className="flex justify-between gap-3">
                                    <span>Célösszeg:</span>
                                    <span>{toCurrency(ambassador.donation_goal)}</span>
                                </div>
                            </div>
                            <div className="flex flex-1">
                                <StaticProgressBar progress={ambassador.donation_amount / ambassador.donation_goal * 100} variant="primary" />
                            </div>
                        </div>
                        {donations.length > 0 && <DonationsTable donations={donations} maxEntries={5} />}
                    </div>
                    {(campaign?.active && (!ambassador.end_date || ambassador.end_date >= new Date())) && (
                        <DonationForm />
                    )}
                </div>
            ) : (
                <div className="flex flex-col flex-1 items-center justify-center">
                    <Loader className="w-12" />
                </div>
            )}
        </div>
    )
}