import { useGlissadeButton } from "@enymo/glissade";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React from "react";
import Loader from "./Loader";

export default function IconButton({ 
    children,
    onClick: onClickProp,
    loading: loadingProp,
    disabled: disabledProp,
    submit,
    variant = "standard",
    compact = false,
    className,
    ...props
}: {
    children: React.ReactNode,
    variant?: "standard" | "filled" | "filled-tonal" | "outlined" | "error"
    loading?: boolean;
    compact?: boolean;
    inline?: boolean;
} & Omit<ClickableProps, "children">) {

    const { disabled, loading, onClick} = useGlissadeButton({ 
        onClick: onClickProp, 
        disabled: disabledProp, 
        loading: loadingProp, 
        submit 
    });

    return (
        <Clickable
            className={classNames("flex items-center justify-center rounded-full [&_svg]:size-5 size-10 cursor-pointer", className, {
                "cursor-not-allowed": disabled,
                "interactive-bg-surface [&_svg]:fill-on-surface": variant === "standard",
                "interactive-bg-primary [&_svg]:fill-on-primary": variant === "filled",
                "interactive-bg-secondary-container [&_svg]:fill-on-secondary-container": variant === "filled-tonal",
                "interactive-bg-surface border border-outline [&_svg]:fill-on-surface": variant === "outlined",
                "interactive-bg-error-container [&_svg]:fill-on-error-container": variant === "error",
                "h-5 w-8 [&_svg]:size-[15px]": compact,
            })}
            onClick={onClick} 
            disabled={disabled} 
            {...props}
        >
            {!loading && children}
            {loading && <Loader />}
        </Clickable>
    )
}