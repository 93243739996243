import PublicCampaign from "@/js/Pages/Public/Campaign";
import React from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import Gate from "./Gate";
import AppLayout from "./Layouts/App";
import Lockscreen from "./Layouts/Lockscreen";
import Public from "./Layouts/Public";
import Ambassador from "./Pages/App/Ambassador";
import Campaign from "./Pages/App/Campaign";
import Campaigns from "./Pages/App/Campaigns";
import CampaignTemplatePreview from "./Pages/App/CampaignTemplatePreview";
import Organization from "./Pages/App/Organization";
import OrganizationTemplatePreview from "./Pages/App/OrganizationTemplatePreview";
import UserSettings from "./Pages/App/UserSettings";
import Error from "./Pages/Error";
import ForgotPassword from "./Pages/Lockscreen/ForgotPassword";
import Invitation from "./Pages/Lockscreen/Invitation";
import Login from "./Pages/Lockscreen/Login";
import ResetPassword from "./Pages/Lockscreen/ResetPassword";
import PublicAmbassador from "./Pages/Public/Ambassador";
import DonationSuccessful from "./Pages/Public/DonationSuccessful";
import PublicOrganization from "./Pages/Public/Organization";
import CampaignAmbassadorsProvider from "./Providers/App/CampaignAmbassadorsProvider";
import AmbassadorProvider from "./Providers/Public/AmbassadorProvider";
import PublicCampaignProvider from "./Providers/Public/CampaignProvider";
import UserProvider from "./Providers/UserProvider";
import { CampaignProvider } from "./resources";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Navigate to="/public" />} />
                <Route element={<UserProvider />}>
                    <Route path="/login" element={<Gate realm="login" />}>
                        <Route element={<Lockscreen />}>
                            <Route index element={<Login />} />
                            <Route path="forgot-password" element={<ForgotPassword />} />
                            <Route path="reset-password" element={<ResetPassword />} />
                            <Route path="invitation" element={<Invitation />} />
                        </Route>
                    </Route>
                    <Route path="/app" element={<Gate realm="app" />}>
                        <Route element={<AppLayout />}>
                            <Route index element={<Navigate to="/app/campaigns" />} />
                            <Route path="campaigns" element={<CampaignProvider children={<Outlet />} />}>
                                <Route index element={<Campaigns />} />
                                <Route path=":campaignId" element={<CampaignAmbassadorsProvider children={<Outlet />} />}>
                                    <Route index element={<Campaign />} />
                                    <Route path="preview" element={<CampaignTemplatePreview />} />
                                    <Route path="ambassadors/:ambassadorId" element={<Ambassador />} />
                                </Route>
                            </Route>
                            <Route path="organization">
                                <Route index element={<Organization />} />
                                <Route path="preview" element={<OrganizationTemplatePreview />} />
                            </Route>
                            <Route path="user-settings" element={<UserSettings />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="/public" element={<Gate realm="public" />}>
                    <Route index element={<Navigate to="/public/organizations/1" />} />
                    <Route path="organizations/:organizationId" element={<Public />}>
                        <Route index element={<PublicOrganization />} />
                        <Route path="donation-successful" element={<DonationSuccessful />} />
                        <Route path="campaigns/:campaignId" element={<PublicCampaignProvider />}>
                            <Route index element={<PublicCampaign />} />
                            <Route path="donation-successful" element={<DonationSuccessful />} />
                            <Route path="ambassadors/:ambassadorId" element={<AmbassadorProvider />}>
                                <Route index element={<PublicAmbassador />} />
                                <Route path="donation-successful" element={<DonationSuccessful />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route path="403" element={<Error type="403" />} />
                <Route path="*" element={<Error type="404" />} />
            </Routes>
        </BrowserRouter>
    )
}